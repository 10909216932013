import MainPage from '@/views/MainPage.vue';
import MintPage from '@/views/MintPage.vue';
import { createRouter, createWebHistory } from 'vue-router';


const routes = [
  {
    path: '/',
    name: 'MainPage',
    component: MainPage // Use MainPage.vue as the layout for the Main Page
  },
  // {
  //   path: '/mint',
  //   name: 'MintPage',
  //   component: MintPage
  // }
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

export default router;
